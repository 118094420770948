/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// --------------------------------------------------------------------------------
// --- Logo and Font --------------------------------------------------------------
// --------------------------------------------------------------------------------

@font-face {
	font-family: "FaunaProRegular";
	src: url("../fonts/FaunaProRegular.ttf");
	font-display: block; /* Fixes font flickering */
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text {
	font-family: "FaunaProRegular" !important;
	font-size: 1.75rem !important;
	color: #06B7EF !important;
	margin-top: 7px;
}

.brand-logo {
	display: flex;
}

.brand-text {
	font-family: "FaunaProRegular" !important;
	font-size: 1.3rem !important;
}
/*
.main-menu-content {
	margin-top: 50px;
}
*/


.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
	//left: calc(50% - 258px) !important; // left 50% - branding width -> center between nav content
	left: 50% !important;
    transform: translate(-50%, 0) !important;
}

.navbar-nav {
	align-items: center;
  justify-content: center;
}

// --------------------------------------------------------------------------------
// --- Fix padding bug for static horizontal menu ---------------------------------
// --------------------------------------------------------------------------------

@media only screen and (max-width: 768px) {
	// For static navbar
	html body .app-content {
		padding: 2rem 2rem 0 2rem !important;
	 }
}